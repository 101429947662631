import {HCTheme} from '@hconnect/uikit'
import * as d3 from 'd3'
import React, {useRef} from 'react'

import {ChartBundleSizeInBytesAxisY} from './ChartBundleSizeInBytesAxisY'
import {ChartNumberOfFilesAxisX} from './ChartNumberOfFilesAxisX'
import {BarChartEntry, Iso8601} from './CoverageChart.types'

interface DatetimeValue {
  datetime: Iso8601
  value: number
}

interface BundleChartProps {
  data: BarChartEntry[]
  minValue?: number
  maxValue?: number
  chartHeight?: number
}

export const toPixel = (number: number): string => `${number}px`

export const ChartBundleSizeInBytes: React.FC<BundleChartProps> = ({
  data,
  minValue,
  maxValue,
  chartHeight = 200
}) => {
  const svgRef = useRef<SVGSVGElement>(null)

  // Configurable variables
  const chartWidth = svgRef?.current?.parentElement?.clientWidth ?? 400

  const paddingLeft = 78
  const paddingRight = 48
  const paddingTop = 16
  const paddingBottom = 100

  // D3 calculations
  const [defaultMin, defaultMax] = d3.extent(data, (item) => item.value)

  const yScale = d3
    .scaleLinear()
    .domain([minValue ?? defaultMin ?? 0, maxValue ?? defaultMax ?? 1])
    .range([chartHeight - paddingBottom, paddingTop])

  const xScaleDomain = [
    d3.min(data, (item: BarChartEntry) => new Date(item.datetime)) ?? 0,
    d3.max(data, (item: BarChartEntry) => new Date(item.datetime)) ?? 1
  ]

  const xScale = d3
    .scaleTime()
    .domain(xScaleDomain)
    .range([0, chartWidth - paddingLeft - paddingRight])

  const line = d3
    .line<DatetimeValue>()
    .x((d: BarChartEntry) => xScale(new Date(d.datetime)) ?? 0)
    .y((d: BarChartEntry) => yScale(d.value) ?? 0)

  const path = line(data) ?? undefined

  return (
    <svg
      ref={svgRef}
      height={chartHeight}
      width={chartWidth}
      viewBox={`0 0 ${chartWidth} ${chartHeight}`}
    >
      <ChartBundleSizeInBytesAxisY scale={yScale} x={paddingLeft} y={paddingTop} />
      <ChartNumberOfFilesAxisX
        scale={xScale}
        x={paddingLeft}
        y={chartHeight + (paddingTop - paddingBottom)}
      />

      <path
        d={path}
        stroke={HCTheme.palette.primary.light}
        strokeWidth="2"
        fill="none"
        transform={`translate(${paddingLeft} ${paddingTop})`}
      />
    </svg>
  )
}
