/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios, {AxiosRequestConfig} from 'axios'
import globalThis from 'globalthis'

import {
  convertAuthToApiToken,
  extractBaseUrlFromAccessToken,
  getAuthorizationHeader,
  getURLEncodedContentTypeHeader,
  hasAccessTokenExpired
} from '../../tools'
import {
  AuthenticatorResponse,
  ClientConfig,
  PersistedToken,
  RefreshTokenRequest,
  RevokeRefreshTokenRequest,
  TokenClientConfig
} from '../../types'
import {createErrorInterceptor, createOnResponseInterceptor} from '../response'

import {createClientCredentialsInterceptor} from './clientCredentials'
import {createOnRequestInterceptor} from './onRequestCallback'

const browserGlobal = globalThis()

const TokenSchemaId = 'TokenSchemaId'

export function createAuthenticationClient(networkConfig: Partial<ClientConfig>) {
  const authClient = axios.create({})
  authClient.interceptors.request.use(createOnRequestInterceptor(networkConfig))
  authClient.interceptors.response.use(createOnResponseInterceptor(networkConfig))
  authClient.interceptors.request.use(
    createClientCredentialsInterceptor({authRequestProvider: networkConfig.authRequestProvider!}),
    createErrorInterceptor(networkConfig)
  )

  return authClient
}

const OPENID_REFRESH_PATH = '/connect/token'

const SITEFINITY_TOKEN_PATH = '/Sitefinity/Authenticate/OpenID'
const SITEFINITY_REFRESH_PATH = '/users/login'

/**
 * Fetches new tokens and returns the new token info object.
 *
 * @returns The new token info object.
 */
export const requestNewAccessToken = async (
  clientConfig: ClientConfig
): Promise<PersistedToken> => {
  const {loginStorage, loginFlow, backendSelector} = clientConfig
  const persistedToken = loginStorage.getToken()

  if (!persistedToken || !persistedToken.refreshToken) {
    await loginFlow.startLoginProcess()
    throw new Error('Missing refresh token')
  }

  console.debug(
    `Access token expired - renewing tokens with refresh token: ${persistedToken.refreshToken}`
  )

  // `bodyParams` and `url` are used in the `try` and the `catch` block
  const body: RefreshTokenRequest = {
    refresh_token: persistedToken.refreshToken,
    grant_type: 'refresh_token'
  }

  // Reverting to get baseURL from the issuer
  // Build the url from the issuer along with 'connect/token'
  // Pass to the authenticationApi as full url
  const baseUrlFromToken = extractBaseUrlFromAccessToken(persistedToken.accessToken) || ''

  // Hack for fixing path issues in Sitefinity
  const url = baseUrlFromToken.includes(SITEFINITY_TOKEN_PATH)
    ? `${backendSelector.getSelectedBackend().API_URL}${SITEFINITY_REFRESH_PATH}`
    : `${baseUrlFromToken}${OPENID_REFRESH_PATH}`

  const authenticationApi = createAuthenticationClient(clientConfig)

  try {
    const headers = {
      ...getURLEncodedContentTypeHeader()
    }
    const config = {headers, schemaID: TokenSchemaId}
    const response = await authenticationApi.post(url, body, config)
    const data: Required<AuthenticatorResponse> = response.data
    return convertAuthToApiToken(data)
  } catch (error: any) {
    const {response, JSONResponseValidationErrors} = error
    if (JSONResponseValidationErrors) {
      console.error(`Invalid token: ${JSON.stringify(JSONResponseValidationErrors)}`)
    }

    const {message} = error

    const isRefreshTokenInvalid = !!response && response.status === 400
    if (!isRefreshTokenInvalid) {
      console.debug(`Failed to fetch new tokens: ${message}`)

      // If the error that was caught above is not related to an invalid refresh token
      // and we don't handle it here, then we must throw it again to the calling function.
      // This error bubbles all way through to the appropriate callee function, e.g. getOrders()
      throw error
    } else {
      console.warn(`Refresh token expired: ${message}`)
      await loginFlow.startLoginProcess()

      // Return a promise that never resolves to avoid further code to be executed
      return new Promise((): void => {})
    }
  }
}

// `updatePromise` contains the promise returned by `fetchNewTokens`.
// It is used to accumulate the listeners of any subsequent `getFreshTokenInfo` calls.
// This makes sure that all parallel requests do wait for the 1st `fetchNewTokens` call to complete.
let checkAccessTokenFreshness: Promise<PersistedToken> | null = null

/**
 * Returns a function that manages the token retrieval and update.
 */
function getFreshToken(clientConfig: ClientConfig): Promise<PersistedToken> {
  if (checkAccessTokenFreshness) {
    if (browserGlobal.API_CLIENT_DEBUG_TOKEN) {
      console.log('API-Client: Use Existing Freshness Checker [1]')
    }

    return checkAccessTokenFreshness
  }

  checkAccessTokenFreshness = (async () => {
    // This 'await' is added on purpose to delay the executing to outside the function scope.
    // Especially the 'checkAccessTokenFreshness = null' statement must be executed AFTER getFreshToken returns.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const currentToken = await clientConfig.loginStorage.getToken()
    const tokenExpired = hasAccessTokenExpired(currentToken.accessToken)

    if (!tokenExpired) {
      checkAccessTokenFreshness = null
      return currentToken
    }

    const newToken = await requestNewAccessToken(clientConfig)
    clientConfig.loginStorage.setToken(newToken)

    if (browserGlobal.API_CLIENT_DEBUG_TOKEN) {
      console.log('API-Client: Reset Existing Freshness Checker [2]')
    }

    checkAccessTokenFreshness = null
    return newToken
  })()

  if (browserGlobal.API_CLIENT_DEBUG_TOKEN) {
    console.log('API-Client: Return New Freshness Checker [3]')
  }

  return checkAccessTokenFreshness
}

export const createTokenHandlerRequestInterceptor =
  (clientConfig: ClientConfig) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const {accessToken} = await getFreshToken(clientConfig)

    const headers = {
      ...getAuthorizationHeader(accessToken)
    }

    return {...config, headers}
  }

export const createTokenHandlerRequestInterceptorWithToken =
  (clientConfig: TokenClientConfig): ((config: AxiosRequestConfig) => AxiosRequestConfig) =>
  (config) => {
    const headers = {
      ...getAuthorizationHeader(clientConfig.token)
    }

    return {...config, headers}
  }

export const requestRevokeRefreshToken = async (clientConfig: ClientConfig) => {
  const {loginStorage} = clientConfig
  const persistedToken = loginStorage.getToken()

  const baseUrlFromToken = extractBaseUrlFromAccessToken(persistedToken.accessToken) || ''
  const url = `${baseUrlFromToken}/connect/revocation`
  const authenticationApi = createAuthenticationClient(clientConfig)
  const body: RevokeRefreshTokenRequest = {
    client_id: process.env.REACT_APP_CLIENT_ID ?? 'HConnect',
    token_type_hint: 'refresh_token',
    token: persistedToken.refreshToken
  }

  const headers = {
    ...getURLEncodedContentTypeHeader()
  }
  const config = {headers, schemaID: TokenSchemaId}

  await authenticationApi.post(url, body, config)
}
