/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  alpha,
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  SvgIconProps,
  TextField
} from '@material-ui/core'
import {KeyboardArrowDown, Search} from '@material-ui/icons'
import classNames from 'classnames'
import {isEmpty, omit} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Divider, {DividerColor} from '../Divider'
import TitleButton from '../Title/Components/TitleButton'
import Typography from '../Typography'

import useDropdownStyle from './DropdownStyles'

/**
 * Function which filters out options based on typed term
 *
 * @param options array of items which should be rendered as select options
 * @param stringifyFunc function that stringify the object
 * @param search search term
 * @param stringifySubFunc (optional) if dropdown has nested list, this function should be applied
 * @returns array of filtered items by search term
 *
 */
export const filteredOptions = (
  options: any[],
  search: string,
  stringifyFunc?: (o: any) => string,
  stringifySubFunc?: (o: any) => string
  // eslint-disable-next-line max-params
): any[] => {
  const res = options.filter((option: any) => {
    const normalizedOption = stringifyFunc ? stringifyFunc(option).toLowerCase() : ''
    const termFounded = search
      .toLowerCase()
      .split(/\s+/)
      .every((subTerm: string) => normalizedOption.includes(subTerm))
    if (stringifySubFunc && option.items && option.items.length > 0) {
      // be careful - recursion bellow
      const filteredSubItems = filteredOptions(option.items, search, stringifySubFunc)
      return termFounded || filteredSubItems.length > 0
    }
    return termFounded
  })
  return res
}

interface Props {
  label: string
  noSelectionLabel?: string
  options?: any[] // FIXME: add type
  showError?: boolean
  errorRender?: () => React.ReactNode
  stringifyItem: (item: any) => string // FIXME: add type for item
  stringifySubItem?: (item: any) => string
  renderChosenItem?: (item: any) => string
  renderItem: (item: any) => React.ReactNode
  onChange?: (val: any) => void
  onSearchTermChange?: (term: string) => void
  loading?: boolean
  disabled?: boolean
  highlighted?: boolean
  getSelectedItemTestValue?: (item: any) => string
  selectedItem: any // FIXME: add type
  selectedProps?: any // FIXME: add type
  showSearch?: boolean
  className?: string
  dark?: boolean
  long?: boolean
  onLight?: boolean
  isDisabled?: (item: any) => boolean
  renderSubItem?: (item: any) => React.ReactNode
  SubItemIcon?: React.FC<SvgIconProps>
  keyExtractor?: (item: any) => string
  'data-test-id'?: string
  subLabel?: (item: any) => string
  disabledCustomItem?: React.ReactNode
  inWidget?: boolean
}

const SelectDropdown = ({
  label,
  noSelectionLabel,
  options = [],
  loading,
  disabled,
  highlighted,
  errorRender = () => 'Something went wrong',
  showError = false,
  stringifyItem,
  stringifySubItem,
  renderChosenItem,
  renderItem,
  renderSubItem,
  onChange = () => undefined,
  onSearchTermChange = () => undefined,
  selectedItem,
  getSelectedItemTestValue,
  selectedProps = {},
  showSearch = true,
  className: classNameProp,
  dark = false,
  long = false,
  onLight = false,
  isDisabled,
  SubItemIcon,
  'data-test-id': dataTestId,
  subLabel,
  disabledCustomItem,
  inWidget = false
}: Props) => {
  const classes = useDropdownStyle()
  const {t} = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)
  const anchorEl = useRef()

  useEffect(() => {
    onSearchTermChange(searchTerm)
  }, [searchTerm])

  if (loading) {
    return (
      <div className={classes.root} aria-label={`${label} dropdown`}>
        <TitleButton
          className={classNames(
            classes.button,
            {
              [classes.dark]: dark
            },
            classNameProp
          )}
          long={long}
          buttonRef={anchorEl}
          onClick={() => setOpen((value) => !value)}
        >
          <div {...selectedProps}>
            <Typography variant="caption" customColor={dark ? 'textSecondary' : 'textPrimarySoft'}>
              {label}
            </Typography>
            <div className={classes.textField}>
              <Typography
                variant={long && !inWidget ? 'h2' : 'h4'}
                color={dark ? 'textSecondary' : 'textPrimary'}
                noWrap
              >
                {t('loading')}&#8230;
              </Typography>
              <KeyboardArrowDown
                className={classNames({[classes.arrow]: !long, [classes.arrowWhite]: long})}
              />
            </div>
          </div>
        </TitleButton>
        <Popover
          open={open}
          anchorEl={anchorEl.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          classes={{paper: onLight ? classes.popoverOnLight : classes.popover}}
          style={{left: -20}}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            padding={5}
          >
            <CircularProgress />
          </Box>
        </Popover>
      </div>
    )
  }

  const stringifiedLabel = (
    selected: any,
    strItem: (item: any) => string,
    strSubItem?: (item: any) => string
  ) => {
    const splittedLiteral = strItem(selected).split(' ')

    if (strSubItem && splittedLiteral.includes('undefined')) {
      return strSubItem(selected)
    }
    return strItem(selected)
  }
  const renderLabel = (
    selected: any,
    strItem: (item: any) => string,
    strSubItem?: (item: any) => string,
    renderChosenItem?: (item: any) => string
  ) => {
    if (renderChosenItem) {
      return renderChosenItem(selected)
    }
    return stringifiedLabel(selected, strItem, strSubItem)
  }
  const selectedLabel = selectedItem
    ? renderLabel(selectedItem, stringifyItem, stringifySubItem, renderChosenItem)
    : noSelectionLabel

  const selectedSubLabel = selectedItem && subLabel ? subLabel(selectedItem) : null

  return (
    <div className={classNames({[classes.disabled]: disabled})} aria-label={`${label} dropdown`}>
      <TitleButton
        data-test-id={dataTestId}
        className={classNames(
          classes.button,
          {
            [classes.dark]: dark
          },
          classNameProp
        )}
        long={long}
        highlighted={highlighted}
        buttonRef={anchorEl}
        onClick={() => setOpen((value) => !value)}
      >
        <div {...selectedProps}>
          <Typography variant="caption" customColor={dark ? 'textSecondary' : 'textPrimarySoft'}>
            {label}
          </Typography>
          <div
            className={
              long && !inWidget
                ? classes.textFieldLong
                : long && inWidget
                ? classes.textFieldLongAndInWidget
                : classes.textField
            }
          >
            <Typography
              variant={long && !inWidget ? 'h2' : 'h4'}
              color={dark ? 'textSecondary' : 'textPrimary'}
              noWrap
              aria-details={getSelectedItemTestValue?.(selectedItem) || ''}
            >
              {selectedLabel}
            </Typography>
            <KeyboardArrowDown
              className={classNames({[classes.arrow]: !long, [classes.arrowWhite]: long})}
            />
          </div>
          {selectedSubLabel ? (
            <Typography
              variant="caption"
              customColor={dark ? 'textSecondary' : 'textPrimarySoft'}
              noWrap
              style={{display: 'block'}}
            >
              {selectedSubLabel}
            </Typography>
          ) : null}
        </div>
      </TitleButton>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{paper: onLight ? classes.popoverOnLight : classes.popover}}
        style={{left: -20}}
      >
        {showError ? (
          errorRender()
        ) : (
          <>
            {showSearch && (
              <div className={classes.searchContainer}>
                <TextField
                  label={t('dropdown.searchList')}
                  autoFocus
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  InputLabelProps={{
                    classes: {
                      root: onLight ? classes.searchInputLabelOnLight : classes.searchInputLabel,
                      focused: onLight
                        ? classes.searchInputLabelFocusedOnLight
                        : classes.searchInputLabelFocused
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      'aria-label': `${label} lookup input`,
                      'data-test-id': 'input-search'
                    },
                    classes: {
                      root: onLight ? classes.searchInputOnLight : classes.searchInput,
                      underline: onLight
                        ? classes.searchInputUnderlineOnLight
                        : classes.searchInputUnderline
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                        aria-label={`${label} search icon`}
                      >
                        <Search style={{color: 'inherit'}} />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            )}
            <MenuList
              classes={{root: classes.menu}}
              aria-label={`${label} filter list`}
              aria-details={`${dataTestId}-filter-list`}
            >
              {noSelectionLabel && [
                <Divider key="divider" color={onLight ? 'onLight' : 'onBlue'} />,
                <MenuItem
                  key="menuItem"
                  classes={{
                    root: onLight ? classes.itemOnLight : classes.item,
                    selected: onLight ? classes.selectedOnLight : classes.selected
                  }}
                  selected={!selectedItem}
                  onClick={(e) => {
                    setOpen(false)
                    onChange(null)
                    setSearchTerm('')
                  }}
                >
                  <Typography
                    variant="body1"
                    color={onLight ? 'textPrimary' : 'textSecondary'}
                    component="span"
                    aria-label={`${label} filter option ${noSelectionLabel}`}
                  >
                    {noSelectionLabel}
                  </Typography>
                </MenuItem>
              ]}
              {options.length === 0 || !noSelectionLabel ? null : (
                <Divider color={onLight ? 'onLight' : 'onBlue'} />
              )}
              {filteredOptions(options, searchTerm, stringifyItem, stringifySubItem).map(
                (option: any, index: number) => {
                  const isLastItem = index === options.length - 1
                  const onlySubItems = isEmpty(omit(option, 'items'))
                  const disabled = isDisabled ? isDisabled(option) : false
                  return (
                    <Box
                      key={stringifyItem(option)}
                      display="flex"
                      flexDirection="column"
                      data-test-id={`dropdown-filter-option-${
                        option?.value ? option?.value : index
                      }`}
                      aria-label={`Site filter option ${index}`}
                    >
                      {!onlySubItems ? (
                        <MenuItem
                          classes={{
                            root: onLight ? classes.itemOnLight : classes.item,
                            selected: onLight ? classes.selectedOnLight : classes.selected
                          }}
                          onClick={(e) => {
                            setOpen(false)
                            onChange(option)
                          }}
                          selected={selectedItem === option}
                          disabled={disabled}
                        >
                          <Typography variant="body1" color="textSecondary" component="span">
                            {renderItem(option)}
                          </Typography>
                        </MenuItem>
                      ) : null}
                      {(!isDisabled &&
                        renderSubItem &&
                        stringifySubItem &&
                        option.items &&
                        option.items.length > 0) ||
                      (disabled && option.items && option.items.length > 1)
                        ? filteredOptions(option.items, searchTerm, stringifySubItem).map(
                            (item: any) => (
                              <>
                                <Box
                                  key={
                                    stringifySubItem ? stringifySubItem(item) : JSON.stringify(item)
                                  }
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                  aria-label={`Site filter option ${index}`}
                                >
                                  <MenuItem
                                    classes={{
                                      root: onLight ? classes.itemOnLight : classes.item,
                                      selected: onLight ? classes.selectedOnLight : classes.selected
                                    }}
                                    onClick={(e) => {
                                      setOpen(false)
                                      onChange(item)
                                    }}
                                    selected={selectedItem === item}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                      paddingLeft={onlySubItems ? 1 : 1.625}
                                    >
                                      {SubItemIcon && (
                                        <SubItemIcon
                                          style={{marginRight: 8, fontSize: 15, marginTop: 4}}
                                        />
                                      )}
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        component="span"
                                        classes={{root: classes.fontSize}}
                                        aria-details={
                                          getSelectedItemTestValue?.(selectedItem) || ''
                                        }
                                      >
                                        {renderSubItem ? renderSubItem(item) : ''}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                </Box>
                                {onlySubItems && <Divider color={onLight ? 'onLight' : 'onBlue'} />}
                              </>
                            )
                          )
                        : null}
                      {!isLastItem && <Divider color={onLight ? 'onLight' : 'onBlue'} />}
                    </Box>
                  )
                }
              )}
              {disabledCustomItem ? (
                <>
                  <Divider
                    color={(onLight ? 'onLightDisabled' : 'onBlueDisabled') as DividerColor}
                  />
                  <MenuItem
                    disabled
                    classes={{
                      root: onLight
                        ? classNames(classes.itemOnLight, classes.itemDisabledOnLight)
                        : classNames(classes.item, classes.itemDisabled)
                    }}
                  >
                    {disabledCustomItem}
                  </MenuItem>
                </>
              ) : null}
            </MenuList>
          </>
        )}
      </Popover>
    </div>
  )
}

interface GetDropdownSelectedItemArgs {
  items: any[]
  isSubItem: boolean
  selectedItemId?: string
  itemKey: string
  subItemKey: string
}

const getDropdownSelectedItem = ({
  items,
  isSubItem,
  selectedItemId,
  itemKey,
  subItemKey
}: GetDropdownSelectedItemArgs) => {
  if (!selectedItemId) {
    return undefined
  }

  for (const item of items) {
    if (item[itemKey] === selectedItemId && !isSubItem) {
      return item
    }

    for (const subItem of item.items) {
      if (subItem[subItemKey] === selectedItemId) {
        return subItem
      }
    }
  }
}

export {SelectDropdown, getDropdownSelectedItem}
