import {Paper} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import React, {ReactChild, ReactChildren} from 'react'

import type {HCThemeType} from '../HCTheme.types'

const useStyles = makeStyles((theme: HCThemeType) => ({
  primary: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  contrast: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary
  }
}))

export enum CardVariant {
  primary = 'primary',
  contrast = 'contrast'
}

interface CardProps {
  variant?: CardVariant
  children?: ReactChild | ReactChildren
}

export const Card: React.FC<CardProps> = ({variant = CardVariant.primary, children}) => {
  const classes = useStyles()
  const root = classes[variant]
  return <Paper classes={{root}}>{children}</Paper>
}
