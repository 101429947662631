import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import Typography from './Typography'

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'uppercase',
    fontSize: '1.5625rem',
    letterSpacing: '2.5px',
    fontWeight: 500
  },
  gutterBottom: {
    marginBottom: 48
  }
}))

interface PageTitleProps {
  className?: string
  gutterBottom?: boolean
}

const PageTitle: React.FC<PageTitleProps> = ({children, ...otherProps}) => {
  const classes = useStyles()

  return (
    <Typography
      color="inherit"
      component="h2"
      variant="h1"
      classes={{
        root: classes.root,
        gutterBottom: classes.gutterBottom
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default PageTitle
